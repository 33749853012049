'use client';

import { ReactNode, useRef, useState, createContext } from 'react';
import styled from '@emotion/styled';
import { Box, Flex } from '@mezzoforte/forge';
import NextLink from 'components/Link/Link';
import { SubMenu } from './MainNavigationSubMenu';
import { useClickOutside } from 'hooks/useClickOutside';
import { Icon, IconContext } from '@mezzoforte/forge-icons';
import { CompanyIcon } from '../../components/Header/UserGreeting/CompanyIcon';
import { useShouldShowCompanySelector } from '../../hooks/useShouldShowCompanySelector';
import { useCompanySelection } from '../../hooks/useCompanySelection';

interface NavItemBase {
  icon: React.ComponentType<React.ComponentProps<Icon>>;
  label: string;
  type: 'Link' | 'Dropdown' | 'DropdownFullwidth' | 'Button';
}

export interface NavItemLink extends NavItemBase {
  type: 'Link';
  href: string;
}

export interface NavItemDropdown extends NavItemBase {
  type: 'Dropdown';
  customMenu?: ReactNode;
  items?: NavSubItem[][];
}

export interface NavItemDropdownFullwidth extends NavItemBase {
  type: 'DropdownFullwidth';
  customMenu?: ReactNode;
  items?: NavSubItem[][];
}

export interface NavItemButton extends NavItemBase {
  type: 'Button';
  onActivate: () => void;
}

export type NavItemProps = NavItemButton | NavItemLink | NavItemDropdown | NavItemDropdownFullwidth;

interface NavSubItemBase {
  label: string;
  type: 'Link' | 'Text' | 'UserGreeting';
}

export interface NavSubItemLink extends NavSubItemBase {
  type: 'Link';
  href: string;
}

export interface NavSubItemText extends NavSubItemBase {
  type: 'Text';
}

export interface NavSubItemUserGreeting extends NavSubItemBase {
  type: 'UserGreeting';
}

export type NavSubItem = NavSubItemLink | NavSubItemText | NavSubItemUserGreeting;

export const NavItemContext = createContext({ close: () => {} });

export const NavItem: React.FC<NavItemProps> = item => {
  const { selectedCompany } = useCompanySelection();
  const { shouldShowCompanySelector } = useShouldShowCompanySelector();
  const [isActive, setIsActive] = useState(false);
  const element = useRef<HTMLDivElement & HTMLLIElement>(null);
  const hasSubmenu = item.type === 'Dropdown' || item.type === 'DropdownFullwidth';

  const hideSubmenu = () => setIsActive(false);
  useClickOutside(element, hideSubmenu);

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = event => {
    if (item.type === 'Link') return;
    event.preventDefault();
    if (item.type === 'Button') return item.onActivate();
    setIsActive(val => !val);
  };

  // OK to use Link when no navigation is intended: https://www.w3.org/WAI/tutorials/menus/flyout/#use-parent-as-toggle
  return (
    <NavItemContext.Provider value={{ close: hideSubmenu }}>
      <Box
        ref={element}
        as="li"
        key={item.label}
        listStyleType="none"
        position={{ base: 'static', lg: item.type === 'DropdownFullwidth' ? 'static' : 'relative' }}
        data-test="main-nav-item"
      >
        <Link
          href={item.type === 'Link' ? item.href : '#'}
          aria-expanded={hasSubmenu ? isActive : undefined}
          onClick={onClick}
          // We have too many non-nextjs pages as Prismic links to justify prefetching
          prefetch={false}
        >
          <Flex flexDir="column" alignItems="center">
            {item.icon.displayName === 'UserCircle' && selectedCompany && shouldShowCompanySelector ? (
              <CompanyIcon company={selectedCompany} />
            ) : (
              <IconContext.Provider value={{ size: 24 }}>
                <item.icon />
              </IconContext.Provider>
            )}
            <Box
              as="span"
              fontSize={{ base: 12, lg: 14 }}
              lineHeight={{ base: '19px', lg: '18px' }}
              minW="56px"
              textAlign="center"
            >
              {item.label}
            </Box>
          </Flex>
        </Link>
        {hasSubmenu && <SubMenu display={isActive} item={item} />}
      </Box>
    </NavItemContext.Provider>
  );
};

const Link = styled(NextLink)`
  text-decoration: none;

  span + span {
    border-bottom: 1px solid transparent;
  }

  &[aria-expanded='true'] span + span {
    border-bottom-color: white;
  }

  color: inherit;
`;
