'use client';

import { MainNavigationItemSliceUserMenu } from 'prismicio-types';
import { NavItem } from 'features/SiteHeader/MainNavigationItem';
import { useLoginModal } from 'hooks/useLoginModal';
import { useSession } from 'hooks/useSession';
import { MainNavigationItemProps } from './index';
import { icons, menuItemsFromSlice } from './util';
import { useClientRendering } from 'hooks/useClientRendering';

export function NavItemUserMenu(props: MainNavigationItemProps) {
  const session = useSession();
  const isClient = useClientRendering();
  const { openLoginModal, LoginModal } = useLoginModal();

  const loggedIn = session.currentUser.data?.isAuthenticated ?? false;
  const slice = props.slice as MainNavigationItemSliceUserMenu;

  const label = (() => {
    if (!isClient) return '';
    if (session.currentUser.isLoading) return '';
    if (loggedIn) return slice.primary.title ?? 'Oma sivu';
    return slice.primary.title_for_unauthenticated ?? 'Kirjaudu';
  })();

  return (
    <>
      <NavItem
        type={loggedIn ? 'Dropdown' : 'Button'}
        label={label}
        icon={icons[slice.primary.icon ?? 'List']}
        onActivate={openLoginModal}
        items={menuItemsFromSlice(props.slice, session.currentUser)}
      />
      <LoginModal />
    </>
  );
}
