import React from 'react';
import { Box, Flex, LinkOverlay, LinkBox, Text } from '@mezzoforte/forge';
import { Company } from '../../../types/Company';
import { CompanyIcon } from './CompanyIcon';

export interface CompanyRowProps {
  readonly company: Company;
  readonly onSelect?: (id: number) => void;
}

export const CompanyRow = ({ company, onSelect }: CompanyRowProps) => {
  if (onSelect) {
    return (
      <LinkBox display="block" py={2}>
        <Flex flexDir="row" gap={2}>
          <CompanyIcon company={company} />
          <Text
            fontSize={14}
            fontWeight={500}
            maxWidth={{ base: 'auto', md: 185 }}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            <LinkOverlay href="#" onClick={() => onSelect(company.id)}>
              {company.name}
            </LinkOverlay>
          </Text>
        </Flex>
      </LinkBox>
    );
  }

  return (
    <Box as="span" display="block" py={2}>
      <Flex flexDir="row" gap={2}>
        <CompanyIcon company={company} />
        <Text
          fontSize={14}
          fontWeight={500}
          maxWidth={{ base: 'auto', md: 185 }}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {company.name}
        </Text>
      </Flex>
    </Box>
  );
};
