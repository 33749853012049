import {
  Armchair,
  Bathtub,
  Beach,
  Bicycle,
  Boat,
  Bus,
  Car,
  Chair,
  City2,
  Cylinder,
  Drill,
  Excavator,
  ExhaustPipe,
  Fish,
  Flashlight,
  ForestMachine,
  ForkKnife,
  Forklift,
  GameController,
  Garage,
  Grill,
  Headphones,
  HeavyEquipment,
  House,
  Kitchen,
  Lamp,
  Laptop,
  Lawnmower,
  Lightbulb,
  Map as MapIcon,
  Motorcycle,
  Package,
  Packages,
  PianoKeys,
  Plug,
  Plugs,
  Printer,
  Snowmobile,
  Storefront,
  Tractor,
  Trailer,
  Trophy,
  Truck,
  Tv,
  Van,
  Wall,
  Watch,
  Wheel,
  WoodPlank,
  Wrench,
  type Icon,
} from '@mezzoforte/forge-icons';

import * as c from 'types/Category';
import { categorySlugs } from './category';

export const categoryIcons = new Map<number, Icon>([
  [c.CATEGORY_OTHER, Package],
  [c.CATEGORY_VEHICLES_AND_SUPPLIES, Car],
  [c.CATEGORY_VEHICLE_SUPPLIES, Wheel],
  [c.CATEGORY_REAL_ESTATE, City2],
  [c.CATEGORY_LIGHT_MACHINERY_AND_SUPPLIES, Lawnmower],
  [c.CATEGORY_TOOLS, Drill],
  [c.CATEGORY_FURNITURE_AND_KITCHEN_EQUIPMENT, Lamp],
  [c.CATEGORY_ELECTRONICS, Laptop],
  [c.CATEGORY_JEWELRY_CLOCKS_AND_ANTIQUES, Watch],
  [c.CATEGORY_INDUSTRIAL_MACHINERY, Excavator],
  [c.CATEGORY_BULK_GOODS, Packages],
  [c.CATEGORY_PASSENGER_CAR, Car],
  [c.CATEGORY_VAN, Van],
  [c.CATEGORY_TRUCK, Truck],
  [c.CATEGORY_BOAT, Boat],
  [c.CATEGORY_MOTORCYCLE, Motorcycle],
  [c.CATEGORY_CARAVAN_AND_TRAILER, Trailer],
  [c.CATEGORY_OTHER_VEHICLE, Bus],
  [c.CATEGORY_SNOWMOBILE, Snowmobile],
  [c.CATEGORY_APARTMENT, City2],
  [c.CATEGORY_TIMESHARE, Beach],
  [c.CATEGORY_PREMISES, Storefront],
  [c.CATEGORY_PLOT, MapIcon],
  [c.CATEGORY_CONSTRUCTION_MACHINERY, Excavator],
  [c.CATEGORY_FARMING_MACHINERY, Tractor],
  [c.CATEGORY_FOREST_MACHINERY, ForestMachine],
  [c.CATEGORY_OTHER_MACHINERY, Forklift],
  [c.CATEGORY_CONSTRUCTION_SUPPLIES, WoodPlank],
  [c.CATEGORY_HOUSEHOLD_APPLIANCES, Plug],
  [c.CATEGORY_FURNITURE, Chair],
  [c.CATEGORY_IT_EQUIPMENT, Laptop],
  [c.CATEGORY_OTHER_ELECTRONICS, GameController],
  [c.CATEGORY_FREE_TIME, Flashlight],
  [c.CATEGORY_MACHINERY_SUPPLIES, Package],
  [c.CATEGORY_VACATION_HOMES, House],
  [c.CATEGORY_GARAGES, Garage],
  [c.CATEGORY_HOBBY_SUPPLIES, Bicycle],
  [c.CATEGORY_BICYCLES, Bicycle],
  [c.CATEGORY_FISHING_AND_HUNTING, Fish],
  [c.CATEGORY_INSTRUMENTS_AND_MUSIC, PianoKeys],
  [c.CATEGORY_GARDEN, Lawnmower],
  [c.CATEGORY_GARDEN_FURNITURE, Grill],
  [c.CATEGORY_OUTDOOR_LIGHTS, Lightbulb],
  [c.CATEGORY_YARD_DECORATION, Wall],
  [c.CATEGORY_YARD_BUILDINGS, House],
  [c.CATEGORY_TOOLS_AND_TOOLSETS, Drill],
  [c.CATEGORY_HANDCRAFT_TOOLS, Wrench],
  [c.CATEGORY_CONSTRUCTION, WoodPlank],
  [c.CATEGORY_BATHROOM_RENOVATION, Bathtub],
  [c.CATEGORY_KITCHEN_RENOVATION, Kitchen],
  [c.CATEGORY_HVAC_SUPPLIES, Cylinder],
  [c.CATEGORY_ELECTRONICS_SUPPLIES, Plugs],
  [c.CATEGORY_TABLEWARE, ForkKnife],
  [c.CATEGORY_DECOR, Lamp],
  [c.CATEGORY_ANTIQUE, Armchair],
  [c.CATEGORY_AUDIO, Headphones],
  [c.CATEGORY_TV, Tv],
  [c.CATEGORY_PHONE_AND_IT_ACCESSORIES, Printer],
  [c.CATEGORY_COLLECTIBLES, Watch],
  [c.CATEGORY_OTHER_COLLECTIBLES, Trophy],
  [c.CATEGORY_HEAVY_EQUIPMENT, HeavyEquipment],
  [c.CATEGORY_HEAVY_EQUIPMENT_SPARE_PARTS, ExhaustPipe],
  [c.CATEGORY_VEHICLE_SPARE_PARTS, ExhaustPipe],
]);

export const categoriesStaticData = new Map<number, c.CategoryStaticData>(
  Array.from(categorySlugs.entries()).map(([id, slug]) => [id, { slug, icon: categoryIcons.get(id)! }])
);
